import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Logo from "../images/themework-logo.svg"

import { btn } from "../sass/04-components/button.module.scss";
import styles from "../sass/05-pages/home.module.scss"

const Layout = ({ children }) => (
  <div>
    <header className="c-header">
      <Link to="/">
        <img src={Logo} alt="Themework Logo" />
      </Link>

      <nav className="c-site-nav">
        <Link className="c-site-nav__item" to="/#features">Features</Link>
        <a className={btn} href="https://airtable.com/shr0BW9N16uLibEXg" target="_blank" rel="noreferrer">Request a Demo</a>
      </nav>
    </header>
    <div className="c-content">
      <main>{children}</main>
      <footer className={styles.footer}>
        <div className={styles.footerInner}>
          <div className={styles.footerAbout}>
            <img src={Logo} alt="Themework Logo" />
            <p>&copy; 2020 Themework</p>
            <p>All rights reserved</p>
          </div>
          <div className={styles.footerLegal}>
              <h4>Legal</h4>
              <Link className="c-link" to="/privacy">Privacy Policy</Link>
              <Link className="c-link" to="/terms">Terms and Conditions</Link>
              <a className="c-link" href="https://airtable.com/shrAb2aWA7S2MkMC0" target="_blank" rel="noreferrer">Contact Us</a>
          </div>
          <div className={styles.footerSocial}>
            <h4>Socials</h4>
            <a className="c-link" href="https://twitter.com/ThemeworkApp" target="_blank" rel="noreferrer">Twitter</a>
            <a className="c-link" href="https://www.linkedin.com/company/themework" target="_blank" rel="noreferrer">LinkedIn</a>
            <a className="c-link" href="https://medium.com/@themework" target="_blank" rel="noreferrer">Medium</a>
          </div>
        </div>
      </footer>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
